<template>
  <div class="login-container">
    <img class="background" src="@/assets/images/login/bgImg .png" alt="登录" />
    <div class="login-main">
      <h2>灭火救援沙盘推演系统</h2>
      <div class="login-main_content">
        <div>
          <el-form ref="formData" :model="loginData" :rules="loginRules">
            <el-form-item prop="username">
              <el-input v-model="loginData.username" name="username" placeholder="请输入账号" clearable @keypress.enter.native="handleLogin">
                <img slot="prefix" src="@/assets/images/login/userIcon.png" alt="账号" />
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="loginData.password" name="password" type="password" clearable placeholder="请输入密码" show-password @keypress.enter.native="handleLogin">
                <img slot="prefix" src="@/assets/images/login/passWordIcon.png" alt="密码" />
              </el-input>
            </el-form-item>
          </el-form>

          <el-checkbox v-model="rememberPassword">记住密码</el-checkbox>

          <el-button class="login-btn" type="primary" @click="handleLogin">登 录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Storage from '@/utils/Storage'

const accountStorage = new Storage('accountInfo')

const loginRules = {
  username: [{ required: true, trigger: ['blur', 'change'], message: '请输入用户名' }],
  password: [{ required: true, trigger: ['blur', 'change'], message: '请输入密码' }]
}

export default {
  data() {
    return {
      loginData: {
        username: '',
        password: ''
      },
      loginRules,

      rememberPassword: false
    }
  },

  created() {
    this.checkIsRemember()
  },

  methods: {
    checkIsRemember() {
      let accountData = accountStorage.getStorage()

      if (accountData) {
        this.rememberPassword = true
        accountData = JSON.parse(accountData)
        this.loginData.username = accountData.username
        this.loginData.password = accountData.password
      }
    },

    handleLogin() {
      this.$refs.formData.validate((valid) => {
        if (!valid) return

        const loading = this.$loading({
          lock: true,
          text: '登录中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        if (this.rememberPassword) {
          accountStorage.setStorage(JSON.stringify(this.loginData))
        }

        this.$store
          .dispatch('user/silentLogin', this.loginData)
          .then(() => {
            this.$router.replace({ path: '/' })
          })
          .finally(() => {
            setTimeout(() => loading.close(), 2000)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.login-container {
  @include size(100vw, 100vh);
  position: relative;
}

.background {
  @include size(100%);
  position: absolute;
  top: 0;
  left: 0;
}

.login-main {
  @include flex-center(center, column);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h2 {
    font-size: 52px;
    font-weight: bold;
    color: #3d5166;
    background: linear-gradient(0deg, #4facfe 0%, #00f2fe 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 40px;
  }

  &_content {
    @include size(660px, 534px);
    background: url('~@/assets/images/login/bianKuangImg.png') no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    @include flex-center(center);

    & > div {
      width: 401px;
      @include flex-center(center, column);
      align-items: flex-start;

      .el-form {
        width: 100%;
        .el-form-item {
          margin: 0;
          &:last-of-type {
            margin-top: 30px;
          }
        }
      }

      .el-checkbox {
        margin-top: 23px;
        margin-bottom: 30px;
      }

      .login-btn {
        @include size(100%, 50px);
      }
    }
  }
}
</style>
